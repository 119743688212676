.navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #b9579f;
    padding: 10px;
  }
  
  .navbar-item {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .navbar-item img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .navbar-item img:hover {
    transform: scale(1.3);
  }