/* Import Pacifico font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.flower-animation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Title styling with full-width background */
.flower-title {
  font-family: 'Pacifico', cursive;
  font-size: 36px;
  color: #ff66a3;
  background-color: #ffc8f3;
  width: 100%;  /* Ensure it spans the full width */
  text-align: center;
  margin: 0;
  padding: 20px 0;  /* Add some padding for spacing */
}

.flower-animation-frame {
  width: 100%;
  height: 100%;
  border: none;
}
