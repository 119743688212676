/* Import Pacifico and Caveat font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Caveat&display=swap');

.photo-gallery {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 86.5vh;
  background-color: #ffe6f2;
  padding-top: 40px;
}

/* Title styling with white background strip */
.gallery-title {
  font-family: 'Pacifico', cursive;
  font-size: 36px;
  color: #ff66a3;
  margin: 0;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  position: fixed; /* Keeps it fixed at the top */
  top: 0;
  z-index: 1000; /* Ensure it stays above the rest of the content */
}

.photo-container {
  position: relative;
  max-width: 60%;
  max-height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 100px; /* Pushes the gallery down to make space for the title */
}

.photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Caption styling with blue color and Caveat font */
.caption {
  margin-top: 15px;
  font-size: 24px;
  font-family: 'Caveat', cursive;
  color: rgb(25, 109, 255);
  opacity: 1;
  transition: opacity 0.5s ease;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.arrow.left {
  left: 40px;
}

.arrow.right {
  right: 40px;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
