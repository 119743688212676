.password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffe6f2;
  border: 5px solid transparent;
  background-image: url('./flower-border.jpg');
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover; /* Adjust the size to cover the page */
  background-position: center; /* Center the image */
}

.password-page h1 {
  font-family: 'Pacifico', cursive;
  color: #ff66b2;
}

.font-style {
  font-family: 'Pacifico', cursive;
}

.password-input {
  margin-bottom: 20px; /* Add margin to the text field */
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
